import React, {useState} from "react";
import {Link} from "react-router-dom";
import Logout from "../../logout/index";
import {logout} from "../../../data/actions/user";

export default (props) => {

    const [menu, setMenu] = useState(false)
    return (
        <React.Fragment>
            <div className={"navigation-wrapper " + (props.menuOpen ? "open-menu" : "")}
                 onMouseOver={() => setMenu(true)} onMouseLeave={() => setMenu(false)}>
                <div className="link-wrapper">


                    <div className="link-border"></div>
                    <div className="link">
                        <Link to={`/users`}>
                            <i class="fas fa-users"></i>
                            {props.translate("nav.users")}
                        </Link>
                    </div>
                    <div className="link-border"></div>
                    <div className="link">
                        <Link to={`/reports`}>
                            <i class="fas fa-exclamation-triangle"></i>
                            {props.translate("nav.reports")}
                        </Link>
                    </div>
                    <div className="link-border"></div>
                    <div className="link">
                        <Link to={`/support`}>
                            <i className="fas fa-question-circle"></i>
                            {props.translate("nav.support")}
                        </Link>
                    </div>
                    <div className="link-border"></div>
                    <div className="link" onClick={()=> props.dispatch(logout({}))}>
                        <i class="fas fa-sign-out-alt"></i>

                        <Logout history={props.history} translate={props.translate} dispatch={props.dispatch}></Logout>
                    </div>
                    <div className="link-border"></div>
                </div>
            </div>
        </React.Fragment>
    );
}