import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/layout";
import LoaderSmall from "../../components/loader-small";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import {getResource, updateResource} from "../../data/actions/resource";
import BlockedImagesDialog from '../../components/blockedImagesDialog'
import BlockingImagesDialog from '../../components/blockingImagesDialog'
import Pagination from '../../components/pagination'
import UpdateStatus from "../updateStatus";
import moment from "moment";

class ReportsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blockedImagesDialog: false,
            blockingImagesDialog: false,
            blockedImages: [],
            blockingImages: [],
            offset: 0,
            limit: 10,
            paginationPage: 1,
            input: '',
            updateStatusDialog: false,
            status: 0,
            id: 0,
            searchOption: "blocked",
            status: false,
            blockedButton: true,
            blockerButton: false
        };
        this.escapeDialogHandler = this.escapeDialogHandler.bind(this);
    }

    componentDidMount() {
        this.fetchData()
        document.addEventListener("keydown", this.escapeDialogHandler, false)
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escapeDialogHandler, false);
    }

    escapeDialogHandler(event) {
        if (event.keyCode === 27) {
            this.setState({blockedImagesDialog: false})
            this.setState({blockingImagesDialog: false})
        }
    }

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    fetchData = () => {
        if (this.state.searchOption == "blocker") {
            this.props.dispatch(getResource({
                user: LocalStorage.get("user"),
                resource: `api/adminblock`,
                query: Object.assign({
                    offset: this.state.offset,
                    limit: this.state.limit
                }, !!this.state.input ? {blocker: this.state.input} : {}, this.state.status ? {status: this.state.status} : {})
            }))
        } else if (this.state.searchOption == "blocked") {
            this.props.dispatch(getResource({
                user: LocalStorage.get("user"),
                resource: `api/adminblock`,
                query: Object.assign({
                    offset: this.state.offset,
                    limit: this.state.limit
                }, !!this.state.input ? {blocked: this.state.input} : {}, this.state.status ? {status: this.state.status} : {})
            }))
        }
    };
    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }
    closeblockedImagesDialog = () => {
        this.setState({blockedImagesDialog: false})
    }
    closeblockingImagesDialog = () => {
        this.setState({blockingImagesDialog: false})
    }
    closeStatusDialog = () => {
        this.setState({updateStatusDialog: false})
    }
    setStatus = (id, status) => {
        this.setState({updateStatusDialog: true, status: status, id: id})
    }
    returnStatus = num => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            resource: 'api/block',
            piggyResource: 'api/adminblock',
            params: Object.assign({
                id: this.state.id,
                status: num
            }),
            query: Object.assign({
                offset: this.state.offset,
                limit: this.state.limit
            }, !!this.state.input ? {blocked: this.state.input} : {})
        }));
    }
    setBlocked = () => {
        this.setState({searchOption: "blocked", blockedButton: true, blockerButton: false}, this.fetchData)

    }
    setBlocker = () => {
        this.setState({searchOption: "blocker", blockedButton: false, blockerButton: true}, this.fetchData)
    }
    setStatusOne = () => {
        this.setState({status: 1}, this.fetchData)
    }
    setStatusTwo = () => {
        this.setState({status: 2}, this.fetchData)
    }
    setStatusAll = () => {
        this.setState({status: false}, this.fetchData)
    }
    banUser = (id) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            resource: 'api/block',
            piggyResource: 'api/adminblock',
            params: Object.assign({
                id: id,
                status: 0
            }),
            query: Object.assign({
                offset: this.state.offset,
                limit: this.state.limit
            }, !!this.state.input ? {blocked: this.state.input} : {})
        }));
    }

    render() {
        const {translate, resource} = this.props;
        const data = getProp(resource.data, "items", [])
        const isLoading = getProp(resource, "isLoading", []);
        const blockedList = data.map(item => (
            <div>
                <div className="table">
                    <div className="column cursor"
                         onClick={() => {
                             this.setState({blockedImagesDialog: true, blockedImages: item.blockedImages})
                         }}>
                        <i class="far fa-images"></i>
                    </div>
                    <div className="column">
                        {item.blockedName}
                    </div>
                    <div className="column cursor"
                         onClick={() => {
                             this.setState({blockingImagesDialog: true, blockingImages: item.blockingImages})
                         }
                         }>
                        <i class="fas fa-images"></i>
                    </div>
                    <div className="column">
                        {item.blockingName}
                    </div>
                    <div className="column">
                        {moment(item.time).format("DD/MM/YYYY | HH:mm:ss")}
                    </div>
                    <div className="column status" onClick={() => this.setStatus(item.id, item.status)}>
                        {item.status}
                    </div>
                    <div className="column">
                        {item.description}
                    </div>
                    <div className="column ban-btn cursor" onClick={() => this.banUser(item.id)}>
                        <span>BAN</span>
                    </div>

                </div>
                <hr/>
            </div>
        ))
        return (
            <PublicLayout {...this.props}>
                <div className="test">
                    <h1 className={"text-center"}>{translate("text.reports")}</h1>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input"
                        value={this.state.input}
                        onChange={e => {
                            this.handleFilterChange("input", e.target.value);
                        }}
                    />
                </div>
                <div className="buttonsD">
                    <div
                        onClick={this.setBlocked}
                        className={`buttonD ${this.state.blockedButton ? "selected" : ""}`}>Blocked
                    </div>
                    <div
                        onClick={this.setBlocker}
                        className={`buttonD ${this.state.blockerButton ? "selected" : ""}`}>Blocker
                    </div>
                    <div
                        onClick={this.setStatusAll}
                        className={`buttonD ${this.state.status == false ? "selected" : ""}`}>Status : ALL
                    </div>
                    <div
                        onClick={this.setStatusOne}
                        className={`buttonD ${this.state.status == 1 ? "selected" : ""}`}>Status : 1
                    </div>
                    <div
                        onClick={this.setStatusTwo}
                        className={`buttonD ${this.state.status == 2 ? "selected" : ""}`}>Status : 2
                    </div>
                </div>

                <div className="table">
                    <div className="column">
                        <b>Blocked images</b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Blocked name</b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Blocking images</b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Blocking name</b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Report time </b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Status </b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Description</b><i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>Ban User </b><i class="fas fa-chevron-down"></i>
                    </div>

                </div>
                <hr/>
                {/* {getProp(resource, "data.count", 0) > 0 ? 
                  blockedList.length > 0 ? blockedList : <LoaderSmall />
                : 
                <h1>No data</h1>} */}
                {}
                {isLoading ?
                    <LoaderSmall/>
                    :
                    blockedList.length > 0 ? blockedList : <h1>no data</h1>
                }
                {this.state.blockedImagesDialog &&

                <BlockedImagesDialog
                    closeDialog={this.closeblockedImagesDialog}
                    blockedImages={this.state.blockedImages}
                />
                }

                {this.state.blockingImagesDialog &&
                <BlockingImagesDialog
                    closeDialog={this.closeblockingImagesDialog}
                    blockingImages={this.state.blockingImages}
                />
                }
                <div className="width-90">

                {getProp(resource.data, "count",0) >0 &&
                    <Pagination
                    updateOffset={this.updateOffset}
                    paginationButtonLimit={5}
                    limit={this.state.limit}
                    offset={this.state.offset}
                    count={resource.data.count}
                    paginationPage={this.state.paginationPage}
                />}
                </div>
                {this.state.updateStatusDialog &&
                <UpdateStatus
                    closeStatusDialog={this.closeStatusDialog}
                    status={this.state.status}
                    returnStatus={this.returnStatus}
                />
                }
            </PublicLayout>
        );
    }
}

export default connect(state => state)(ReportsView);