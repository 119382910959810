import React, {Component} from 'react'
import {connect} from "react-redux";
import Dropzone from "react-dropzone";
import {onDropAccepted} from 'react-dropzone'
import {updateImage, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import FieldSelectSearch from "../field-select-search";
import {FieldsManager} from "../../data/services/fields";


class AddImagesDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            images: null,
            files:[],
            dropzoneActive: false,
            offset:0,
            limit:10,
            filename:"",
            slot: [
                {value: 1, label: '1'},
                {value: 2, label: '2'},
                {value: 3, label: '3'},
            ],
            slotValue:null
        }
    }

    fileSelectedHandler = (event) => {
        this.setState({images: event.target.files[0]}, () => console.log(this.state.images))
    }

    setImages = (id) => {
        this.props.dispatch(updateImage({
            user: LocalStorage.get('user'),
            resource: 'api/image',
            piggyResource: 'api/adminusers',
            params: Object.assign({
                Slot: this.state.slotValue,
            }),
            Image: this.state.files,
            ProfileID: id,
            query: Object.assign({
                offset: this.state.offset,
                limit: this.state.limit
            }, !!this.state.input ? {blocked: this.state.input} : {})
        }));
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    handleInputChange = (name, value) => {
        this.setState({slotValue:value.value})
    };

    render() {
        const {translate} = this.props
        return (
            <div>
                <div className="images-dialog">

                    <div className="add-new-image">
                        <div className={"flex-between"}>
                            <Dropzone
                                className={"border"}
                                onDrop={(acceptedFiles) => {
                                    this.setState({
                                        files: acceptedFiles.map(file => Object.assign(file, {
                                            preview: URL.createObjectURL(file)
                                        })),
                                        canSubmit: true,

                                    },()=> this.setState({filename:this.state.files[0].name}));
                                }}
                                onDragEnter={this.onDragEnter}
                                onDragLeave={this.onDragLeave}
                                onDropAccepted={this.onDrop}
                                maxFiles={1}
                                accept={"image/jpg, image/jpeg, image/png"}
                                multiple={false}
                            >
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}
                                             className={" " + (this.state.dropzoneActive ? "active" : "")}>
                                            <input {...getInputProps()} />
                                            <p>{translate("field.dnd_user_image")}</p>
                                            <em>{translate("field.image_types")}</em>
                                            <p>{this.state.filename}</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <div className="block-margin-bottom-10" style={{width:"140px"}}>
                                <label>Slot</label>
                                <FieldSelectSearch
                                    values={this.state.slot}
                                    className="field-select-search"
                                    onChange={this.handleInputChange} {...this.state.slot}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: 'black',
                                            primary25: 'black',
                                            primary: 'black',
                                        },
                                    })}
                                />
                            </div>


                        </div>
                        <span className={"addnewbtn"} onClick={() => this.setImages(this.props.id)}>Upload</span>
                        <div className="inner-dialog-close">
                            <i onClick={() => this.props.closeImagesDialog()} className="fas fa-times"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => state)(AddImagesDialog);