import React, {useEffect, useState} from 'react'
import axios from 'axios';
import LocalStorage from '../../util/localStorage';
import LoaderSmall from "../../components/loader-small";

const BlockedImagesDialog = (props) => {

    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        props.blockedImages.forEach(element => {
            axios
                .get('https://dev-api.balkandejt.com/api/image/' + element.name, {
                    responseType: "arraybuffer",
                    headers: {Authorization: 'Bearer ' + LocalStorage.get('user').token}
                }).then((response) => {
                let data = `data:${
                    response.headers["content-type"]
                };base64,${new Buffer(response.data, "binary").toString("base64")}`;

                images.push(data);
                setImages(Array.from(images));
            });
        });
        setIsLoading(false)
    }, [])

    const img = images.map((it) => (
        <img className="block-image" src={it}/>
    ))

    return (
        <div>
            <div className="images-dialog">

                {isLoading ?
                    <LoaderSmall/>
                    :
                    props.blockedImages.length > 0 ?
                        <div className="block-images">
                            {img}
                        </div>
                        :
                        <h1>No images to show</h1>
                }
                <div className="dialog-close">
                    <i onClick={() => props.closeDialog()} class="fas fa-times"></i>
                </div>
            </div>
        </div>
    )
}


export default BlockedImagesDialog