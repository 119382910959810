import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Login from "./views/login";
import HomeView from "./views/home";
import ResetPasswordConfirm from "./views/reset-password-confirm";
import EmailConfirm from "./views/email-confirm";
import LocalStorage from "./util/localStorage";
import NotFoundView from "./views/not-found";
import RegisterView from "./views/register";
import PromoView from "./views/promo";
import DashboardView from "./views/dashboard";
import UsersView from "./views/users";
import ReportsView from "./views/reports";
import PrivacyPolicy from "./views/privacy-policy";
import SupportView from "./views/support";
import PublicSupportView from "./views/public-support";
import Eula from "./views/eula";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/users",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

class RouterListener extends Component {

    constructor(props) {
        super(props);

        this.unlisten = null;
        this.state = {}
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {

        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <Switch>
                    <NeutralRoute exact path="/" component={(props) => getComponent(PromoView, props)}/>
                    <NeutralRoute exact path="/public-support" component={(props) => getComponent(PublicSupportView, props)}/>


                    <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                    <NeutralRoute exact path="/privacy-policy"
                                  component={(props) => getComponent(PrivacyPolicy, props)}/>
                    <NeutralRoute exact path="/terms_of_use"
                                  component={(props) => getComponent(Eula, props)}/>
                    <PrivateRoute exact path="/register" component={(props) => getComponent(RegisterView, props)}/>


                    {/*<PublicRoute exact path="/reset-password"
                                 component={(props) => getComponent(ResetPassword, props)}/>*/}
                    <NeutralRoute exact path="/reset-password-confirm/:token/:email"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                    <NeutralRoute exact path="/confirm-email/:token/:email"
                                  component={(props) => getComponent(EmailConfirm, props)}/>


                    <PrivateRoute exact path="/home" component={(props) => getComponent(HomeView, props)}/>

                    <PrivateRoute exact path="/dashboard" component={(props) => getComponent(DashboardView, props)}/>
                    <PrivateRoute exact path="/users" component={(props) => getComponent(UsersView, props)}/>
                    <PrivateRoute exact path="/reports" component={(props) => getComponent(ReportsView, props)}/>
                    <PrivateRoute exact path="/support" component={(props) => getComponent(SupportView, props)}/>


                    <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                </Switch>
            </RouterListener>
        </Router>
    );
}