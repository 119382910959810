import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/layout";
import LoaderSmall from "../../components/loader-small";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import {getResource} from "../../data/actions/resource";
import Pagination from '../../components/pagination';
import SupportDialog from "../../components/supportDialog";

class SupportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            input: '',
            supportDialogShow:false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: `api/support`,
            query: Object.assign({
                offset: this.state.offset,
                limit: this.state.limit
            }, !!this.state.input ? {name: this.state.input} : {})
        }))
    };

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleSupportInfo = item => {
        this.setState({supportDialogShow:true,items:item})
    }
    closeSupportDialog = () =>{
        this.setState({supportDialogShow:false})
    }

    render() {
        const {translate, resource} = this.props;
        const data = getProp(resource.data, "items", [])
        const users = data.map(item => {
            if(item.from!=undefined || item.from!=null){
                return (
                    <div onClick={()=> this.handleSupportInfo(item)} className={"cursor-pointer"}>
                        <div key={item.id} className="table">
                            <div className="column">
                                {item.from.name}
                            </div>
                            <div className="column">
                                {item.from.age}
                            </div>
                            <div className="column">
                                {item.title}
                            </div>
                            <div className="column free-height">
                                {item.description}
                            </div>
                        </div>
                        <hr/>
                    </div>
                )
            }
        })

        return (
            <PublicLayout {...this.props}>

                <div className="test">
                    <h1 className={"text-center"}>{translate("nav.support")}</h1>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input"
                        value={this.state.input}
                        onChange={e => {
                            this.handleFilterChange("input", e.target.value);
                        }}
                    />
                </div>

                <div className="table">
                    <div className="column">
                        <b>NAME</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>AGE</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>TICKET TITLE</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>TICKET DESCRIPTION</b> <i class="fas fa-chevron-down"></i>
                    </div>
                </div>

                <hr/>
                {resource.isLoading ?
                    <LoaderSmall/>
                    :
                    users.length > 0 ? users : <h1>no data</h1>
                }

                <div className="width-90">
                    {
                        getProp(resource.data, "count",0) >0 &&
                        <Pagination
                            updateOffset={this.updateOffset}
                            paginationButtonLimit={5}
                            limit={this.state.limit}
                            offset={this.state.offset}
                            count={getProp(resource.data, "count",0)}
                            paginationPage={this.state.paginationPage}
                        />
                    }
                </div>

                {this.state.supportDialogShow && (
                    <SupportDialog
                        closeSupportDialog={this.closeSupportDialog}
                        items={this.state.items}
                    />
                )}

            </PublicLayout>
        );
    }
}

export default connect(state => state)(SupportView);