import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';

const createOption = (label) => ({
    label,
    value: label,
});

const components = {
    DropdownIndicator: null,
};

export default class FieldCreatableMultiInput extends Component {

    render() {
        return (
            <div>
                <CreatableSelect
                    components={components}
                    inputValue={this.props.inputValue}
                    value={this.props.value}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(val) => this.props.onChange(this.props.name, val !== null ? val : [])}
                    onInputChange={(val) => this.props.onInputChange(this.props.name, val)}
                    onKeyDown={(event) => {
                        const {inputValue, value} = this.props;
                        if (!inputValue) {
                            return;
                        }
                        switch (event.key) {
                            case 'Enter':
                            case 'Tab':
                                console.group('Value Added');
                                console.log(value);
                                console.groupEnd();
                                this.props.onChange(this.props.name, [...value, createOption(inputValue)]);
                                this.props.onInputChange(this.props.name, "");
                                event.preventDefault();
                        }
                    }
                    }
                    placeholder="Type things that interest you..."
                />
            </div>
        );
    }
}