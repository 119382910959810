import {
    watchEmailConfirmCall,
    watchLoginCall,
    watchRegisterCall,
    watchResetPasswordCall,
    watchResetPasswordConfirmCall
} from "./userSaga";
import {
    watchCreateResource,
    watchDeleteResource,
    watchGetResource,
    watchUpdateFile,
    watchUpdateResource,
    watchUpdateImage
} from './resourceSaga'
import {all} from "redux-saga/effects";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function* rootSaga() {
    yield all([
        watchLoginCall(),
        watchRegisterCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),
        watchEmailConfirmCall(),


        watchGetResource(),
        watchCreateResource(),
        watchUpdateResource(),
        watchDeleteResource(),
        watchUpdateFile(),
        watchUpdateImage()

    ]);
}