import React, {Component} from "react";
import { Link } from "react-router-dom";

export default class NeutralLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            menu: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

    }
    componentDidUpdate() {

    }

    render() {
        return (
            <React.Fragment>
                <div className="neutral-pages">

                    <div className="navigation-container">
                        <Link to="/">
                            <img className="logo-image-neutral" src='../../images/BalkandejtPremium.png' />
                        </Link>

                    </div>

                    <div className="content-wrapper">
                        {this.props.children}
                    </div>

                    <div className="footer">
                        <div className="lines">
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                        <div className="socials">
                            <div className="i-wrapper">
                                <i className="fab fa-facebook-f"></i>
                            </div>
                            <div className="i-wrapper">
                                <i className="fab fa-instagram"></i>
                            </div>
                            <div className="i-wrapper">
                                <i className="fab fa-twitter"></i>
                            </div>
                            <div className="i-wrapper">
                                <i className="fab fa-youtube"></i>
                            </div>
                        </div>
                        <div className="copyright">
                            &copy;Copyright by BalkanDejt Premium - company BD Media AB
                        </div>
                        <div className="copyright">
                            <Link to="/privacy-policy" target="_blank" className={"margin-left-5"}>
                                Privacy Policy
                            </Link>
                            <Link to="/terms_of_use" target="_blank" className={"margin-left-5"}>
                                Terms Of Use
                            </Link>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        )
    }
}