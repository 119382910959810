import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import NeutralLayout from "../../components/layout/neutral";
import LoaderSmall from "../../components/loader-small";

import {EmailConfirm} from "../../data/actions/user";

class EmailConfirmView extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.confirmEmail();
    }

    confirmEmail = () => {
        let email = this.props.match.params && this.props.match.params.email;
        let token = this.props.match.params && this.props.match.params.token;
        email = decodeURIComponent(email);
        token = decodeURIComponent(token);
        this.props.dispatch(EmailConfirm({
            email: email,
            token: token
        }))
    };

    render() {
        const {translate} = this.props;

        return (
            <NeutralLayout {...this.props}>

                <div className="neutral-form-container">

                    <h3>{translate("text.email_confirm")}</h3>

                    {this.props.user.emailConfirm && (
                        <p className="not-found-text">{translate("email_confirm.success")}</p>
                    )}

                    {this.props.user.isLoading && (
                        <LoaderSmall/>
                    )}

                    {this.props.user.error && (
                        <p className="not-found-text">{translate("email_confirm.error")}</p>
                    )}

                </div>

            </NeutralLayout>
        );
    }
}

export default connect(state => state)(EmailConfirmView);