import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateResource,
    doneDeleteResource,
    doneGetResource, doneUpdateImage,
    doneUpdateResource,
    errorCreateResource,
    errorDeleteResource,
    errorGetResource, errorUpdateImage,
    errorUpdateResource
} from "../actions/resource";


export function* getResourceCall(action) {

    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResource(result.data));
    } else {
        yield put(errorGetResource(result.data));
    }
}

export function* watchGetResource() {
    yield takeLatest('GET_RESOURCE', getResourceCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneCreateResource(result.data));
            }
        } else if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }
    } else {
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateResource() {
    yield takeLatest('CREATE_RESOURCE', createResourceCall);
}

export function* updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);
    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {

        if (action.data.Image && action.data.Image.length > 0) {
            yield call(Api.uploadImage, user, action.data.resource, action.data.Image, result.data.ProfileID);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }
    } else {
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateResource() {
    yield takeLatest('UPDATE_RESOURCE', updateResourceCall);
}
export function* updateImageCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result
    if (action.data.Image && action.data.Image.length > 0) {
        result = yield call(Api.uploadImage, user, action.data.resource, action.data.Image, action.data.ProfileID);
    }
    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateImage(result.data));
            }
        } else {
            yield put(doneUpdateImage(result.data));
        }
    } else {
        yield put(errorUpdateImage(result.data));
    }
}

export function* watchUpdateImage() {
    yield takeLatest('UPDATE_IMAGE', updateImageCall);
}

export function* deleteResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDeleteResource(result.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }
    } else {
        yield put(errorDeleteResource(result.data));
    }
}

export function* watchDeleteResource() {
    yield takeLatest('DELETE_RESOURCE', deleteResourceCall);
}

export function* updateFileCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {

        if (action.data.files && action.data.files.length > 0) {
            yield call(Api.uploadFiles, user, "documents/upload", action.data.files, result.data.ids);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }
    } else {
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateFile() {
    yield takeLatest('UPDATE_FILE', updateFileCall);
}