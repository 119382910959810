import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/promo.css";
import { Link } from "react-router-dom";

class PublicSupportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            color: false,
            menu: false,
            isLoading: true
        };
    }

    listenScrollEvent = e => {
        if (window.scrollY > 300) {
            this.setState({ color: true });
            this.setState({ menu: true });
        } else {
            this.setState({ color: false });
            this.setState({ menu: false });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent);
        this.setState({ isLoading: false })
    }

    render() {

        return (
            <React.Fragment>
                {this.state.isLoading ?
                    <div class="loader">
                        <div class="inner one"></div>
                        <div class="inner two"></div>
                        <div class="inner three"></div>
                    </div>
                    :
                    <React.Fragment>
                        {this.state.color && <a href="#container" className="backToTop">

                            <i class="fas fa-chevron-up"></i>

                        </a>}
                        <div >
                            <div className={this.state.menu ? "menu" : "menub"}>
                                <div className="menu-logo">
                                    <img className="logo-image" src={'images/BalkandejtPremium.png'} />
                                </div>
                                <div className={"menu-links "}>
                                    <a href="/">HOME</a>
                                    <a href="/#overview">OVERVIEW</a>
                                    <a href="/public-support">SUPPORT</a>
                                    <a href="/#download">DOWNLOAD</a>
                                </div>
                            </div>
                            <div className="content">
                                <div className="support-text">
                                    <p>Find out more about the BalkanDejt Premium app!</p>

                                    <h2>Application guide</h2>

                                    <p>We are strictly 18+ only, and we work hard to maintain an adults only community. We have a zero-tolerance policy on harassment or inappropriate content.</p>

                                    <p>IMPORTANT: If you notice any inappropriate content or behaviour please use block/report option in the application (either through three dots menu or flag icon) to help us keep application clean.</p>

                                    <p>The application is very intuitive and easy to use. After registering, creating a profile, and sharing your interests with others, the app’s algorithm will select users compatible with you and with whom you would agree ideally.</p>
                                    <p>On the Swipes screen, you can like or dislike another user in two ways. To like, swipe right or click on the heart icon at the bottom of the screen. To dislike, swipe left or click on the X at the bottom of the screen. You can see additional information about the person by swiping the screen down. You also have the option to roll back three viewed users.</p>
                                    <p>The Likes screen shows the photo, name, and age of each person you liked or who liked you. By clicking on the photo, you can see additional information about the person and, if you find them interesting, like them back. By sweeping the screen up, you can see more photos from the given person.</p>
                                    <p>The chat screen is for messaging. You can send up to 3 messages to each user you like, even before they like you back. Keep in mind that the number of messages is limited to 5 for unpaid users.</p>

                                    <p>Our application is completely free for women, and all restrictions that apply to men do not apply to women. So, dear ladies, you are welcome!</p>
                                    <p>On the other hand, men can choose to use the app for free (and have restrictions) or to subscribe to it. You can find subscription information in the Settings menu (open the app > click on your profile > Settings > Subscription)</p>

                                    <p>If you use the application for free, the rules are as follows:</p>
                                    <ul>
                                        <li>The number of swipes is limited to 300. After reaching the limit, the message "You have reached your daily limit" will appear, offering you to subscribe and have an unlimited number of swipes.</li>
                                        <li>When you reach the daily limit, the photos of other people on the Likes screen will be blurred, and tapping on the picture of one of them will again display a message offering you to subscribe.</li>
                                        <li>Free users cannot send messages. You will still be able to see your messages with other users on the Chat screen, but you will not be able to send them new ones.</li>
                                        <li>Ads will be displayed to you.</li>
                                        <li>You won't be able to set video content</li>
                                        <li>Limits are reset every 24 hours.</li>
                                    </ul>

                                    <p>
                                        If you subscribe, there will be no restrictions for you. Also, you will not be shown advertisements.
                                        So, subscribe, enjoy the unlocked features of our app, and indulge in the charms of online dating!
                                        You can cancel your existing subscription at any time through the Settings menu. However, if you do not cancel it at least 24 hours before the previous subscription expires, it will automatically renew.
                                        You can always re-access this screen through the Settings menu.
                                    </p>

                                    <h3>Chat and like data retention policy</h3>

                                    <p>By default chat messages are kept for 6 months, while unanswered likes are kept for 3 months.</p>

                                    <h3>Subscription refund</h3>
                                    <p>
                                        If a user cancels a subscription purchased from an app, our policy is that the user will not receive a refund for the current billing period, but will continue to receive their subscription content for the remainder of the current billing period, regardless of the cancellation date. The user's cancellation goes into effect after the current billing period has passed.
                                    </p>

                                    <h3>Phone upgrade Android to Android, or Apple to Apple</h3>
                                    <p>
                                        If you are just upgrading your phone, there is no need to stress. Log in to your new App Store or Google Play store with the same ID, and your subscription will still be there.
                                    </p>
                                    <h3>Phone upgrade Android to Apple, or Apple to Android</h3>
                                    <p>
                                        Due to both devices belonging to different companies, there is no way to hop from one device’s subscription to another. If you wish to change devices between Apple and Android, you will need to cancel your subscription and subscribe on the other device.
                                    </p>

                                    <h3>Have other questions?</h3>
                                    <p>
                                        You can write us anytime at support@balkandejtpremium.com we will be more than happy yo help you.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="footer">
                            <div className="lines">
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div className="socials">
                                <div className="i-wrapper">
                                    <a href={"https://www.facebook.com/profile.php?id=100083100403993"} target={"_blank"}>
                                        <i className="fab fa-facebook-f"/>
                                    </a>
                                </div>
                                <div className="i-wrapper">
                                    <a href={"https://www.instagram.com/balkandejtpremium/"} target={"_blank"}>
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                            <div className="copyright">
                                &copy;Copyright by BalkanDejt Premium - company BD Media AB
                            </div>
                            <div className="copyright">
                                <Link to="/privacy-policy" target="_blank" className={"margin-left-5"}>
                                    Privacy Policy
                                </Link>
                                <Link to="/terms_of_use" target="_blank" className={"margin-left-5"}>
                                    Terms Of Use
                                </Link>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default connect(state => state)(PublicSupportView);