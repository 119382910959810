import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {Field, FieldsManager} from "../../data/services/fields";
import NeutralLayout from "../../components/layout/neutral";
import FieldPassword from "../../components/field-password";
import Button from "../../components/button";
import {resetPasswordConfirm} from "../../data/actions/user";
import LoaderSmall from "../../components/loader-small";

class ResetPasswordConfirmView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            no_match: false,
            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            }
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submitForm = (event) => {

        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        let email = this.props.match.params && this.props.match.params.email;
        let token = this.props.match.params && this.props.match.params.token;
        email = decodeURIComponent(email);
        token = decodeURIComponent(token);

        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.state.fields.password.value === this.state.fields.password_confirm.value) {
                    this.props.dispatch(resetPasswordConfirm({
                        email: email,
                        token: token,
                        password: this.state.fields.password.value
                    }))
                } else {
                    this.setState({
                        no_match: true
                    })
                }
            }
        })
    };

    render() {
        const {translate} = this.props;

        return (
            <NeutralLayout {...this.props}>

                <form className="neutral-form-container" onKeyPress={this.submitForm}>

                    <h3>{translate("text.reset_password")}</h3>

                    {this.state.no_match && (
                        <p className="not-found-text font-danger">{translate("text.password_fields_match")}</p>
                    )}

                    <div className="input-wrapper">
                        <div className="input-icon">
                            {translate("text.new_password")}
                        </div>
                        <div className="input-field">
                            <FieldPassword
                                onChange={this.handleInputChange} {...this.state.fields.password}
                                placeholder={translate("field.placeholder.new_password")}/>
                        </div>
                    </div>

                    <div className="input-wrapper">
                        <div className="input-icon">
                            {translate("text.new_password_confirm")}
                        </div>
                        <div className="input-field">
                            <FieldPassword
                                onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                placeholder={translate("field.placeholder.password_confirm")}/>
                        </div>
                    </div>

                    <div className="margin-top-10 text-center">

                        <Button className={"submit-form-btn"} onClick={this.submit}
                                text={translate("btn.submit")}/>

                    </div>

                </form>

                {this.props.user.resetConfirm && (
                    <React.Fragment>
                        <div className="neutral-form-container padding-top-20">
                            <p className="not-found-text">{translate("reset_password.success")}</p>
                        </div>
                    </React.Fragment>
                )}

                {this.props.user.isLoading && (
                    <LoaderSmall/>
                )}

                {this.props.user.error && (
                    <div className="neutral-form-container padding-top-20">
                        <p className="not-found-text font-danger">{translate("error.confirm_password_change")}</p>
                    </div>
                )}

            </NeutralLayout>
        );
    }
}

export default connect(state => state)(ResetPasswordConfirmView);