import React, {Component} from "react";
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import NeutralLayout from "../../components/layout/neutral";

class NotFoundView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {translate} = this.props;

        return (
            <NeutralLayout {...this.props}>
                <div className={"neutral-form-container"}>
                    <h2 className="not-found-heading">{translate("page.404.heading_main")}</h2>
                    <p className="not-found-text">{translate("page.404.body")}</p>
                    <Link to={`/home`}>{translate("btn.back_to_home")}</Link>
                </div>
            </NeutralLayout>
        );
    }
}


export default connect(state => state)(NotFoundView);