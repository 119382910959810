import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/layout";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import {getResource} from "../../data/actions/resource";
import Chart from "react-google-charts";

class DashboardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ratioChart: true,
            usersChart: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }


    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: "api/admindashboard"
        }))
    };

    displayRatioChart = () => {
        this.setState({ratioChart: true, usersChart: false})
    };
    displayUsersChart = () => {
        this.setState({ratioChart: false, usersChart: true})
    };

    render() {
        const {translate, resource} = this.props;
        const chartData = [['Country', 'Users']]
        const data = getProp(resource.data, "item", [])
        const maleNumber = getProp(data, "male", [])
        const femaleNumber = getProp(data, "women", [])
        const countries = getProp(data, "countries", [])
        const countrie = countries.map(item => {
            chartData.push([item.name, item.count])
        });

        return (
            <PublicLayout {...this.props}>
                <h1 style={{textAlign: 'center'}}>{translate("text.dashboard")}</h1>
                <h1 style={{textAlign: 'center'}}>Total users: {maleNumber + femaleNumber}</h1>
                <div className="buttonsD">
                    <div
                        className={`buttonD ${this.state.ratioChart ? "selected" : ""}`}
                        onClick={() => this.displayRatioChart()}
                    >Male/Female
                    </div>
                    <div
                        className={`buttonD ${this.state.usersChart ? "selected" : ""}`}
                        onClick={() => this.displayUsersChart()}
                    >Users/Country
                    </div>
                </div>
                <div className="charts">
                    {this.state.ratioChart || this.state.usersChart ? null :
                        <h2 style={{textAlign: 'center'}}>Choose chart you want to be displayed.</h2>}
                    {this.state.ratioChart && <Chart
                        width={700}
                        height={500}
                        margin={0}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['City', '2010 Population'],
                            ['Male', maleNumber],
                            ['Female', femaleNumber],

                        ]}
                        options={{
                            title: 'Male/Female Ratio',
                            chartArea: {width: '30%'},
                            hAxis: {
                                title: 'Total Population',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'City',
                            },
                        }}
                        legendToggle
                    />}

                    {this.state.usersChart && <Chart
                        width={700}
                        height={500}
                        chartType="BarChart"
                        loader={<div>Loading Chart</div>}
                        data={chartData}
                        options={{
                            title: 'Number of users per country',
                            chartArea: {width: '30%'},
                            hAxis: {
                                title: 'Users',
                                minValue: 0,
                            },
                            vAxis: {
                                title: 'City',
                            },
                        }}
                        legendToggle
                    />}
                </div>

            </PublicLayout>
        );
    }
}

export default connect(state => state)(DashboardView);