import React, {Component} from 'react'
import {connect} from "react-redux";
import {toBackDateTime} from "../../util/util";

class SupportDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const {translate, items} = this.props;
        return (
            <div>
                <div className="images-dialog">
                    <div className="items">
                        <div className={"mr-3"}>
                            <p>Name:</p>
                            <p>Age:</p>
                            <p>City:</p>
                            <p>Number Of Children:</p>
                            <p>Date Of Birth:</p>
                            <p>About:</p>
                            <p>Email:</p>
                            <p>Job:</p>
                            <p>Smoker:</p>
                            <p>Gender:</p>
                        </div>
                        <div>
                            <p>{items.from.name}</p>
                            <p>{items.from.age}</p>
                            <p>{items.from.city}</p>
                            <p>{items.from.children}</p>
                            {/*<p>{toBackDateTime(items.from.dateOfBirth).split(" ").[0]}</p>*/}
                            <p>{items.from.about}</p>
                            <p>{items.from.email}</p>
                            <p>{items.from.job}</p>
                            <p>{items.from.smoker?"Yes":"No"}</p>
                            <p>{items.from.gender===1?"Male":"Female"}</p>
                        </div>
                        <div className="inner-dialog-close">
                            <i onClick={() => this.props.closeSupportDialog()} className="fas fa-times"></i>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default connect(state => state)(SupportDialog);