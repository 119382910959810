import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/layout";
import LoaderSmall from "../../components/loader-small";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import {getResource} from "../../data/actions/resource";
import Pagination from '../../components/pagination';
import RegisterView from '../register'
import AddImagesDialog from '../../components/addImagesDialog'
import moment from 'moment'
import BlockingImagesDialog from "../../components/blockingImagesDialog";

class UsersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            input: '',
            addUserIsShown: false,
            addImageIsShown: false,
            viewImageIsShown: false,
            images: null,
            files:[]
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: `api/adminusers`,
            query: Object.assign({
                offset: this.state.offset,
                limit: this.state.limit
            }, !!this.state.input ? {name: this.state.input} : {})
        }))
    };

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData);
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }
    addImage = (id) => {
        this.setState({id: id, addImageIsShown: true})
    }
    closeImagesDialog = () => {
        this.setState({addImageIsShown: false})
    }
    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = (acceptedFiles) => {
        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })),
            canSubmit: true
        },()=> console.log(this.state.files));
    };

    closeAddNewUser = () =>{
        this.setState({addUserIsShown:false})
    }

    viewImages = (images) => {
        this.setState({
            images: images,
            viewImageIsShown: !this.state.viewImageIsShown
        })
    }

    blockUser = (item) => {

    }

    render() {
        const {translate, resource} = this.props;

        const currDate = new Date()
        const data = getProp(resource.data, "items", [])
        const users = data.map(item => {
            return (
                <div>
                    <div key={item.id} className="table">
                        <div className="column">
                            {item.name}
                        </div>
                        <div className="column">
                            {Number(moment(currDate).format("YYYY")) - Number(moment(item.dateOfBirth).format("YYYY"))}
                        </div>
                        <div className="column">
                            {item.education}
                        </div>
                        <div className="column">
                            {item.job}
                        </div>
                        <div className="column">
                            {item.about}
                        </div>
                        <div className="column cursor" >
                            <span className={"ban-btn padding-5"} onClick={() => this.viewImages(item.photos)}>View</span>
                        </div>
                        <div className="column cursor" >
                            <span className={"ban-btn padding-5"} onClick={() => this.blockUser(item)}>Block</span>
                        </div>
                    </div>
                    <hr/>
                </div>
            )
        })

        return (
            <PublicLayout {...this.props}>
                <div className="test">
                  <h1 className={"text-center"}>{translate("text.users")}</h1>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input"
                        value={this.state.input}
                        onChange={e => {
                            this.handleFilterChange("input", e.target.value);
                        }}
                    />
                </div>

                <div className="table">
                    <div className="column">
                        <b>NAME</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>AGE</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>EDUCATION</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>JOB</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>ABOUT</b> <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>IMAGES</b> <i class="fas fa-chevron-down"></i>
                    </div>
                    <div className="column">
                        <b>ACTION</b> <i className="fas fa-chevron-down"></i>
                    </div>
                </div>

                <hr/>
                
                {resource.isLoading ?
                    <LoaderSmall/>
                    :
                    users.length > 0 ? users : <h1>no data</h1>
                }

                <div className="width-90">
                    {
                        getProp(resource.data, "count",0) >0 &&
                        <Pagination
                            updateOffset={this.updateOffset}
                            paginationButtonLimit={5}
                            limit={this.state.limit}
                            offset={this.state.offset}
                            // count={resource.data.count}
                            count={getProp(resource.data, "count",0)}
                            paginationPage={this.state.paginationPage}
                        />
                    }
                </div>

                {this.state.addUserIsShown &&
                    <RegisterView
                        closeAddNewUser={this.closeAddNewUser}
                    />
                }

                {this.state.viewImageIsShown &&
                    <BlockingImagesDialog
                        closeDialog={this.viewImages}
                        blockingImages={this.state.images}
                    />
                }
            </PublicLayout>
        );
    }
}

export default connect(state => state)(UsersView);