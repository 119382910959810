import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy and Safety Center</h1>
            <h3>Privacy Policy last updated on 19. January 2023.</h3>

            <p className="bold">Our Data Controller:</p>
            <p>
                BalkanDejt Premium has appointed a Data Controller within BD Media, Belgrade, Serbia that can be reached by emailing support@balkandejtpremium.com for any questions, comments and complaints about our data practices.
            </p>

            <p className="bold">Our Commitment:</p>
            <p>
                We take very seriously the safety, security and well-being of our users. We take pride in connecting people and while a relatively small percentage of these matches can lead to users falling victim to criminal activity, we firmly believe that any incident of misconduct or criminal behavior is one too many.
            </p>
            <p>
                We are constantly exploring new updates and technologies to enhance and inform our safety efforts while encouraging a respectful environment for meeting new people. Information relating to our safety tools and practices can be found below. Wherever you are our goal is the same: a safe and positive experience for users on our platform.
            </p>

            <p className="bold">Our Safety Tools:</p>
            <p>
                We utilize automated and manual moderation as well as review tools, systems and processes - investing in significant resources to prevent, monitor and stop inappropriate behavior like for example impersonation, harassment and more. **
            </p>
            <p>
                These tools include automatic scans of profiles for red-flag language and images; manual reviews of suspicious profiles, activity, and user generated reports; shadow-banning suspicious accounts; as well as blocking of email addresses, phone numbers, IP addresses and other identifiers associated with bad actors.
            </p>
            <p>
                We have a zero-tolerance policy on harassment and encourage our community to report any instance of misconduct and/or anyone who violates our Community Guidelines or Terms of Use, via the self-reporting tool featured on all our profiles or by contacting our team online. In the event that we receive such a report, our dedicated community team takes appropriate measures, which may include removing the content, banning the user, or notifying the appropriate law enforcement resources.
            </p>
            <p>
                We are constantly evaluating and refining our processes, and will always work with law enforcement, where possible, to protect our users.
            </p>

            <p className="bold">Response to Reports of Assault:</p>
            <p>
                We urge our users to report instances of abuse or assault to us and we strongly encourage any user who believes they have been a victim of a crime to report it to law enforcement. Our team works to promptly investigate reported crimes, assess and take appropriate action, and fully cooperates with law enforcement in any investigation.
            </p>
            <p>
                When a user reports an assault to us, we attempt to identify the alleged perpetrator and block the associated account. The incident is then reported to authorities.
            </p>

            <p className="bold">User Education:</p>
            <p>
                We offer safety tips, both online and via our website, to educate and empower users to make smart and safe choices while interacting with other users here.
            </p>
            <p>View our Tips for Staying Safe here</p>

            <p className="bold">Data Privacy and Platform Security:</p>
            <p>
                Our users’ privacy is a top priority, and we work diligently to ensure that we go above and beyond applicable privacy laws and industry standards. We know that your time on the app is a private matter and have strict policies and technical systems in place, including encryption for user photos and messages and tools that restrict employee access to user communications and other user data. When it comes to our users’ data, our sole mandate is keeping our users safe and their data private.
            </p>
            <p>
                Unlike other social apps, our business model is focused on providing users with premium features, including in-app upgrades and subscriptions, to enhance their experience on our app. We do not sell data to third parties or depend on advertising to maintain or grow our business. Your data is yours. As such, users can request a copy of their data here at any time. The most up-to-date information on our data practices is always available to the general public through our Privacy Policy.
            </p>
            <p>
                Additionally, we are constantly improving our defenses in the battle against those with malicious intent.
            </p>

            <p className="bold">Additional Policies and Information:</p>
            <p>
                Our fundamental goal is to introduce our community of users to new people. And while this is a lot of fun, it’s important to remember that there are Community Guidelines that our users must abide by to help make our the best app for meeting new people.
            </p>
            <p>
                We encourage our users to report any instance of bad behavior on or off our platform. Reporting a user for inappropriate conduct is straightforward and easy to do, just contact us.
            </p>
            <p>
                Below are more details about some of our policies that we have implemented to keep our website a safe place.
            </p>

            <h1>Information we collect</h1>

            <p>
                We appreciate that you trust us with your information, and we intend to keep that trust. However, we need to make sure you understand the information we collect, why we collect it, how it is used, and your choices regarding your information. Keep in mind that we can’t help you develop meaningful connections without some information about you, such as basic profile details. We also collect information about your use of our services, such as access logs and information from third parties, like when you access our services through your social media account or upload information from your social media account to complete your profile. If you want additional info, we go into more detail below.
            </p>

            <h2>Information you give us</h2>

            <p>You choose to give us certain information when using our services which includes:
            </p>
            <ul>
                <li>When you create your account, you provide us with some information about you, such as your name, email address, Facebook ID (if you join through Facebook), and at least one profile photo. In addition, you may allow us to access your camera or photo album to add pictures. This data is mandatory.</li>
                <li>
                When you complete your profile, you may add additional information to improve your profile. This information is optional, shared at your discretion, and is your sole responsibility. However, it will be visible to BalkanDejt users. This data is as follows:
                    <ul>
                        <li>date of birth</li>
                        <li>gender identity</li>
                        <li>country and nationality</li>
                        <li>location</li>
                        <li>height and body type</li>
                        <li>professional career and education: job title or studies;</li>
                        <li>interests and hobbies: personal preferences, leisure activities, musical preferences, lifestyle;
                        </li>
                        <li>if you have or want children</li>
                        <li>if you smoke</li>
                        <li>profile description: if you would like to, you can share additional information about yourself in
                            the space provided for this purpose.</li>
                    </ul>
                </li>

                <li>When you subscribe to a paid service, you will provide Apple’s App Store and Google’s Play Store, which have opted to refrain from sending certain types of data to mobile applications, including banking and financial information. This data is collected and processed solely by the above platforms. BalkanDejt does not have the option of changing this payment method. The only information exchanged between BalkanDejt and Apple’s App Store, or Google’s Play Store is technical data that does not allow us to identify you (for example, a transaction number) and is used to validate transactions. BalkanDejt will retain a history of your purchases and subscriptions but will not store any information relating to the payment methods you use because we do not collect or process any banking data.</li>
                <li>When you participate in surveys, focus groups, or market studies, you give us your insights into our products and services, responses to our questions, and testimonials.</li>
                <li>When you contact our customer services, ask for help, report an issue with our services or report another user, we collect the information you give us during the interaction. Such information is required to handle reports or answer your requests.</li>
                <li>If you share information about other people (for example, if you use the contact details of a friend for a given feature), we process this information on your behalf to complete your request.</li>
                <li>Of course, we also process your chats with other users and the content you publish as necessary for the operation of the services.</li>
            </ul>

            <h2>Information we receive from others</h2>

            <p>In addition to the information you may provide us directly, we receive information about you from others, including:</p>

            <ul>
                <li>Users: Other users may provide information about you as they use our services, for instance, as they interact with you or if they submit a report involving you.</li>
                <li>Social Media: You may decide to share information with us through your social media account. For instance, if you choose to create and log into your BalkanDejt account via your Facebook account or to upload onto our services information, such as photos, from your Facebook account.</li>
                <li>Other Partners: We may receive information about you from our partners where BalkanDejt Premium ads are published on a partner’s service (in which case they may pass along details on a campaign’s success). Where legally allowed, we can also receive information about suspected or convicted bad actors from third parties as part of our efforts to ensure our users’ safety and security.</li>
            </ul>

            <h2>Information collected when you use our services</h2>

            <p>Using our services generates technical data about which features you’ve used, how you’ve used them, and the devices you use to access our services. See below for more details:</p>
            <ul>
                <li>Usage information: Using the services generates data about your activity on our services. For instance, how you use them (e.g., when you logged in, features you’ve been using, actions taken, the information shown to you, referring webpages addresses and ads that you interacted with) and your interactions with other users (e.g., users you connect and interact with, when you exchanged with them, number of messages you send and receive).</li>
                <li>Device information: We collect information from and about the device(s) you use to access our services, including hardware and software information such as IP address, device ID and type, apps settings and characteristics, app crashes, advertising IDs (which are randomly generated numbers that you can reset by going into your device's settings), identifiers associated with cookies or other technologies that may uniquely identify a device or browser.</li>
                <li>Other information with your consent: If you permit us, we can collect your precise geolocation (latitude and longitude). The collection of your geolocation may occur in the background even when you aren’t using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your precise geolocation, we will not collect it. Similarly, we may collect photos (for instance, if you want to publish one on our services).</li>
            </ul>

            <h2>Cookies and similar technologies</h2>

            <p>When you visit our website or when you use our app, we may collect personal data from you automatically by using cookies or similar technologies. A cookie is a small file that can be placed on your device or browser that allows us to recognize and remember you.</p>

            <h1>How do we use your personal information?</h1>

            <p>The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you safe, provide you with advertising that may interest you, and deliver an enjoyable experience to you. To do that, we may use your registration and other information to:</p>

            <ul>
                <li>to fulfill or meet the reason for which the information is provided. For example, to deliver our services;
                </li>
                <li>create, maintain, customize, and secure your account;</li>
                <li>display your profile and content to other BalkanDejt users so that you may interact with each other;</li>
                <li>enable, or add any in-app purchase products to your account;</li>
                <li>provide customer support, respond to your inquiries, troubleshoot issues, manage our services, and respond to requests, questions, and/or comments;</li>
                <li>communicate with you about our services, including notifications based on your account settings, service announcements, and/or administrative messages;</li>
                <li>help us better understand how our services are being used by identifying patterns and trends in usage so that we can design the app and our services better and improve your experience on BalkanDejt Premium;</li>
                <li>identify and fix technical errors;</li>
                <li>maintain the safety, security, and integrity of our app, our website, databases, and services, and prevent, detect, identify, investigate, or respond to potential or actual claims, liabilities, criminal activity, fraud, or malicious activity;</li>
                <li>for testing, research, analytics, and product development, including to develop and improving the BalkanDejt Premium app, website, and services;</li>
                <li>to respond to law enforcement requests and as required by applicable law, court order, or governmental regulations;</li>
                <li>to achieve purposes for which we provide specific notice at the time of collection;</li>
                <li>for any other purpose with your consent.</li>
            </ul>

            <h2>How Do We Share Your Personal Information?</h2>

            <p>
                Our policy is to not disclose or share your personal data without your consent. You chose what information the other Users will see, including your profile picture, so make sure that you are comfortable with the information you provide. We will never publicly disclose any personal and sensitive user data related to financial or payment activities.
            </p>
            <p>
                However, we may disclose some of your personal information to a third party, such as an affiliate, or a service provider, for a business purpose or when it is required by government and law. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to keep that information confidential and not use it for any purpose except performing the contract.
            </p>

            <h2>How Do We Protect Your Personal Information?</h2>

            <p>
                We use appropriate administrative, physical, and technical security measures which are consistent with industry-recognized standards to protect and prevent the loss, misuse, unauthorized access or disclosure and alteration of the information under our control, including your personal data. We use reasonable security measures to safeguard the confidentiality of your personal information.
            </p>
            <p>
                Even so, we cannot guarantee the absolute security, since no Internet, email, or mobile application transmission or method of electronic storage is ever fully secure or error-free. Therefore, you should use caution whenever submitting personal information through our services and take special care in deciding which personal information you provide us with.
            </p>

            <h2>How Long Do We Keep Your Personal Information?</h2>

            <p>
                We retain your Personal Data for no longer than is necessary to fulfill the purposes for which the information was collected or as otherwise permitted or required by applicable law or according to BalkanDejt Premium Terms of Service. However, personal data may be retained for more extended periods for legal or security purposes if needed. In some circumstances, we may also retain chat messages used for reporting violative behavior resulting in an account's ban. If you deactivate your account, we will delete, anonymize or aggregate your information after 90 days from the deactivation date. While your account is deactivated, it is inaccessible to other users. Anonymized and aggregated information is retained for statistical and product research purposes, but this information is not attributable back to an individual.
            </p>
            <p>An exception to our practice is made when:</p>
            <ul>
                <li>we must keep it to comply with applicable law (for example, some “traffic data” is kept for one year to comply with statutory data retention obligations);</li>
                <li>we must keep it to evidence our compliance with applicable law (for instance, records of consents to our Terms, Privacy Policy are kept for five years);</li>
                <li>there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved;</li>
                <li>the information must be kept for our legitimate business interests, such as fraud prevention and enhancing users' safety and security. For instance, information may be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account.</li>
            </ul>

            <h1>How Do You Request That We Delete Your Information?</h1>

            <p>You can request that we delete your personal information by using deactivate account option inside the app or by emailing the subject line "Data Deletion Request" to our Data Controller at support@balkandejtpremium.com. Send the email from the email address associated with your BalkanDejt Premium account in the body of the email.</p>

            <h1>What Are Your Rights?</h1>
            <p>Privacy laws applicable in your country, or if you are located within EEA may give you the following rights:</p>
            <ul>
                <li>The right to be informed of what personal data an organization is processing and why.</li>
                <li>The right to request a copy of your personal data.</li>
                <li>If the data held is inaccurate, you may have the right to have it corrected.</li>
                <li>The right to file a complaint about the use of your data.</li>
                <li>The right to request that your data is transferred to a third party.</li>
                <li>The right to have your data deleted.</li>
            </ul>
            <p>The rights which are applicable to you, not necessarily included above, may vary depending on your country. You should make yourself aware of the rights you have under applicable privacy laws in your country.</p>
            <p>If you want to exercise any of your rights, please email our Data Controller at support@balkandejtpremium.com. For your protection and the protection of all of our Users, we may need to request specific information from you to help us confirm your identity before we can answer the above requests.</p>

            <h1>Acceptance of the Privacy Policy</h1>

            <p>By creating BalkanDejt Premium app account, you are accepting and consenting to the practices set out in this Privacy Policy.</p>
            <p>If we make any changes to the Privacy Policy, the Effective Date of the latest update of this Privacy Policy will be updated. If you continue to use the Service after the changes become effective, then you agree to the revised Privacy Policy. If you do not accept and agree to be bound by all of the terms of our Policy, please do not use the Service.</p>

            <h1>Safety Tips</h1>

            <p>
                Meeting new people is wonderful and can give you butterflies, however you should still always be careful and air on the side of caution when interacting with someone you don’t know. Use your best judgment and put your own safety first, whether exchanging initial messages or meeting someone in person. You can’t control the actions of others, but you can help your own safety and stay safe during your experience here.
            </p>
            <p className="bold">Online Safety</p>
            <p>Never Send Money or Share Financial Information</p>
            <p>
                Never send money, especially by electronic or bank transfer, even if the person claims to be in some kind of emergency. Wiring money is like sending cash and it’s nearly impossible to reverse the transaction or trace where the money went to. Never share information that could be used to access your financial or any other accounts. If another user asks you for money, report it to us immediately.
            </p>

            <p className="bold">Protect Your Personal Information</p>
            <p>
                Never share personal information, such as your social security number, home or work address, or details about your daily routine (e.g., that you go to a certain gym every Monday) with people you don’t know. If you are a parent, limit the information that you share about your children on your profile and in early communications. Avoid sharing details such as your children’s names, where they go to school, or their ages or genders.
            </p>

            <p className="bold">Stay on the Platform</p>
            <p>
                Keep conversations on our platform while you’re getting to know someone as exchanges on our platform are a little safer and more protected, users with bad intentions often try to move the conversation to text, messaging apps, email, or phone right away.
            </p>

            <p className="bold">Be Wary of Long Distance and Overseas Relationships</p>
            <p>
                Watch out for scammers who claim to be from your country but stuck in another country or place, especially if they ask for financial help to get home. Be wary of anyone who will not meet in person or talk on a phone/video call because they may not be who they say they are. If someone is avoiding your questions or pushing for a serious relationship without meeting or getting to know you first that’s a sign of danger!
            </p>

            <p className="bold">Report All Suspicious and Offensive Behavior</p>
            <p>
                You know when someone’s crossed the line and when they do, we want to know about it. Block and report anyone that violates our terms. Here are some examples of violations:
            </p>
            <p>Requests for money or donations</p>
            <p>Underage users</p>
            <p>Harassment, threats, and offensive messages</p>
            <p>Inappropriate or harmful behavior during or after meeting in person</p>
            <p>Fraudulent profiles</p>
            <p>Spam or solicitation including links to commercial websites or attempts to sell products or services</p>

            <p className="bold">Protect Your Account</p>
            <p>
                Be sure to pick a strong password, and always be careful when logging into your account from a public or shared
                computer. We will never send you an email asking for your username and password information and if you receive
                an email asking for account information, report it to us immediately.
            </p>

            <p className="bold">Meeting in Person</p>
            <p>Don’t Be In A Rush</p>
            <p>
                Take your time and get to know the other person before agreeing to meet or chat outside our platform. Don’t be
                afraid to ask questions to look for the signs of danger or personal deal-breakers. A phone or video call can be
                a useful tool before meeting, see the person on video first is a useful way to help you judge if they are
                genuine.
            </p>

            <p className="bold">Meet in Public and Stay in Public</p>

            <p>
                Meet for the first few times in a populated, public place and never at your home or place of work, your date’s
                home, or any other private location. If your date pressures you to go to a private location, finish the date and
                walk away.
            </p>

            <p className="bold">Tell Friends and Family About Your Plans</p>

            <p>
                Tell a friend or family member of your plans, including when and where you’re going. Have your cell phone
                charged and with you at all times.
            </p>

            <p className="bold">Be in Control of Your Transportation</p>

            <p>
                We want you to be in control of how you get to and from your date so that you can leave whenever you want. If
                you’re driving yourself, it’s a good idea to have a backup plan like a ride-share app or a friend to pick you
                up.
            </p>

            <p className="bold">Know Your Limits</p>

            <p>
                Be aware of the effects of drugs or alcohol on you specifically and they can impair your judgment and your
                alertness. If your date tries to pressure you to use drugs or drink more than you’re comfortable with, hold your
                ground and finish the date.
            </p>

            <p className="bold">Don’t Leave Drinks or Personal Items Unattended</p>

            <p>
                Know where your drink comes from and know where it is at all times — only accept drinks poured or served
                directly from the bartender or server. Many substances that are slipped into drinks to facilitate sexual assault
                are odorless, colorless, and tasteless. Also, keep your phone, purse, wallet, and anything containing personal
                information on you at all times.
            </p>

            <p className="bold">If You Feel Uncomfortable, Leave</p>

            <p>
                It’s okay to end the date early if you’re feeling uncomfortable. In fact, it’s encouraged. And if your instincts
                are telling you something is off or you feel unsafe, ask the bartender or server for help.
            </p>

            <p className="bold">Sexual Health & Consent</p>

            <p className="bold">Protect Yourself</p>

            <p>
                When used correctly and consistently, condoms can significantly reduce the risk of contracting and passing on
                STI’s like HIV. But, be aware of STIs like herpes or HPV that can be passed on through skin-to-skin contact. The
                risk of contracting some STIs can be reduced through vaccination.
            </p>

            <p className="bold">Know Your Status</p>
            <p>
                Not all STIs show symptoms, and you don’t want to be in the dark about your status. Stay on top of your health
                and prevent the spread of STIs by getting tested regularly.
            </p>

            <p className="bold">Talk About It</p>
            <p>
                Communication is everything: Before you get physically intimate with a partner, talk about sexual health and STI
                testing. And be aware — in some places, it’s actually a crime to knowingly pass on an STI. Need help starting
                the conversation? Here are some tips. Consent.
            </p>
            <p>
                All sexual activity must start with consent and should include ongoing check-ins with your partner. Verbal
                communication can help you and your partner ensure that you respect each other’s boundaries. Consent can be
                withdrawn at any time, and sex is never owed to anyone. Do not proceed if your partner seems uncomfortable or
                unsure at all, or if your partner is unable to consent due to the effects of drugs or alcohol.
            </p>

            <p className="bold">Resources for Help, Support, or Advice</p>
            <p>
                Remember this, even if you follow these tips, no method of risk reduction is perfect. If you have a negative
                experience, please know that it is not your fault and help is available. Report any incidents to us and consider
                reaching out to one of the resources below. If you feel you are in immediate danger or need emergency assistance.
            </p>
        </div>

    )
}