import axios from "axios";
import Env from "../../util/env";
import {processError, processResponse} from "./api-util";

export default class Api {
    static login(username, password) {
        return axios.post(
            Env.getApiUrl('api/user/login'),
            {
                "Password": password,
                "Email": username
            },
            {
                headers: {
                    'Authorization': 'Bearer TOKEN ' + Env.getPublicToken(),
                    'X-Client': 'Android;8.1;1.0-rc1',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static refresh(token) {
        return axios.post(
            Env.getApiUrl('refresh'),
            'grant_type=refresh_token&refresh_token=' + token,
            {
                headers: {
                    'Authorization': 'Basic ' + Env.getPublicToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            });
    }

    /*static register(params) {
        return axios.post(
            Env.getApiUrl('api/user/register'),
            params,
            {}
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static resetPassword(username) {
        return axios.post(
            Env.getApiUrl('api_pub/reset-password'),
            {
                username: username
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }*/

    static resetPasswordConfirm(token, email, password) {
        return axios.post(
            Env.getApiUrl('api/user/reset-password-confirm'),
            {
                Email: email,
                Token: token,
                Password: password
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static emailConfirm(token, email) {
        return axios.post(
            Env.getApiUrl('api/user/email-confirm'),
            {
                Email: email,
                Token: token
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }

    static getResource(user, query, resource) {
        return axios.get(
            Env.getApiUrl(resource, query),
            {
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                    'X-Client': 'Android;8.1;1.0-rc1',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }


    static createResource(user, params, resource) {
        return axios.post(
            Env.getApiUrl(resource),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                    'X-Client': 'Android;8.1;1.0-rc1',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }

    static updateResource(user, params, resource) {
        return axios.patch(
            Env.getApiUrl(resource),
            params,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                    'X-Client': 'Android;8.1;1.0-rc1',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return {
                    'status': 0,
                    'data': response.data
                };
            })
            .catch((error) => {
                return processError(error);
            })
    }
    static uploadImage(user, resource , Image, id) {
        const requests = Image.map((file, i) => {
            const formData = new FormData();
            formData.append("ProfileID", id);
            formData.append("Image", file, file.name);
            formData.append("Slot", "1");

            return axios.post(
                Env.getApiUrl('api/image'),
                formData,
                {
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }
            )
        });

        return axios.all(requests)
            .then(axios.spread((response) => {
                return processResponse(response);
            }))
            .catch((error) => {
                return processError(error);
            })
    }
    // static uploadFiles(user, resource, files, params = [], descriptions = {}) {
    //     const requests = files.map((file, i) => {
    //         const formData = new FormData();
    //         formData.append('Document', file);
    //
    //
    //         return axios.post(
    //             Env.getApiUrl('api/' + resource),
    //             formData,
    //             {
    //                 headers: {
    //                     'Authorization': 'Bearer ' + user.token,
    //                     'X-Client': 'Android;8.1;1.0-rc1',
    //                     'Content-Type': 'application/json'
    //                 }
    //             }
    //         )
    //     });
    //
    //     return axios.all(requests)
    //         .then(axios.spread((response) => {
    //             return processResponse(response);
    //         }))
    //         .catch((error) => {
    //             return processError(error);
    //         })
    // }
    static uploadFiles(user, resource, files, params = {}) {
        const formData = new FormData();
        formData.append('Document', files);

        Object.keys(params).forEach((it) => {
            formData.append(it, params[it]);
        })

        return axios.put(
            Env.getApiUrl('api/' + resource),
            formData,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                    'X-Client': 'Android;8.1;1.0-rc1',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            })
    }
    static deleteResource(user, query, resource) {
        return axios.delete(
            Env.getApiUrl(resource, query),
            {
                headers: {
                    'Authorization': 'Bearer TOKEN ' + Env.getPublicToken(),
                    'X-Client': 'Android;8.1;1.0-rc1',
                    'Content-Type': 'application/json'
                }
            }
        )
            .then((response) => {
                return processResponse(response);
            })
            .catch((error) => {
                return processError(error);
            });
    }
}