import React from "react";

export default function (props) {
    let list = [];
    let pagesNum = Math.ceil(props.count / props.limit);
    let buttonLimit = (props.paginationButtonLimit) ? props.paginationButtonLimit : 3;

    for (let i = 0; i < (props.count / props.limit); ++i) {

        list.push((

            <li className={"page-item " + (((i * props.limit) === props.offset) ? "active " : (i > (props.paginationPage - buttonLimit - 2) && i < (props.paginationPage + buttonLimit) ? "" : "d-none"))}
                onClick={() => {
                    props.updateOffset(i * props.limit, i + 1);
                }}>
                <span className="page-link">
                    {(i + 1)}
                </span>
            </li>

        ));
    }

    return (
        <React.Fragment>
            <div className="text-center mt-3">
                {!props.hideCaption && (
                    <div className="margin-top-3 mb-3">
                        {(props.count > 0 &&
                            <React.Fragment>
                                {props.offset + 1}
                                -{(props.paginationPage === pagesNum) ? props.count : (props.offset + Number(props.limit))}
                                &nbsp;of&nbsp;
                            </React.Fragment>
                        )}
                        {props.count} items
                    </div>
                )}

                <div className="dataTables_paginate paging_simple_numbers d-inline-block">
                    <ul className="pagination pagination-sm ">
                        <li className={props.paginationPage === 1 ? "paginate_button page-item previous disabled":"paginate_button page-item previous"}
                            onClick={() => (props.paginationPage > 1) ? props.updateOffset((props.paginationPage - 2) * props.limit, props.paginationPage - 1) : null}>
                            <a className="page-link previous" href="#" aria-label="Previous">
                                <span aria-hidden="true"><i className="fas fa-chevron-left"></i></span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {list}
                        <li className={props.paginationPage < pagesNum ? "paginate_button page-item next":"paginate_button page-item next disabled"}
                            onClick={() => props.paginationPage < pagesNum ? props.updateOffset((props.paginationPage) * props.limit, props.paginationPage + 1):null}>
                            <a className="page-link next" href="#" aria-label="Next">
                                <span aria-hidden="true"><i className="fas fa-chevron-right"></i></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}