import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import {Field, FieldsManager} from "../../data/services/fields";
import {createResource} from '../../data/actions/resource'
import Button from "../../components/button/index";
import FieldText from "../../components/field-text/index";
import FieldSelectSearch from '../../components/field-select-search'
import FieldCreatableMultiInput from '../../components/field-creatable-multi-input'
import LocalStorage from "../../util/localStorage";
import FieldDate from '../../components/field-date'

let countries = require('./countries.json');

class RegisterView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: new Field('email', '', ['empty']),
                name: new Field('name', '', ['empty']),
                password: new Field('password', 'qwqw1212', ['empty']),
                date: new Field('date', '', ['empty']),
                gender: new Field('gender', '', ['empty']),
                city: new Field('city', '', ['empty']),
                height: new Field('height', '', ['empty']),
                smoker: new Field('smoker', '', ['empty']),
                pets: new Field('pets', '1', ['empty']),
                education: new Field('education', '', []),
                children: new Field('children', '', []),
                job: new Field('job', '', ['empty']),
                bodyTypeId: new Field('bodyTypeId', '', ['empty']),
                about: new Field('about', '', []),
                country: new Field('country', '', ['empty']),
                interests: new Field('interests', [], []),
            },

            userEducationOptions: [
                {value: 0, label: 'Primary school'},
                {value: 1, label: 'High school'},
                {value: 2, label: 'College'}
            ],
            userKidsOptions: [
                {value: 0, label: 'No answer'},
                {value: 1, label: 'Grown up'},
                {value: 2, label: 'Already have'},
                {value: 3, label: 'No, never'},
                {value: 4, label: 'Someday'}
            ],
            userBodyTypeIdOptions: [
                {value: 0, label: 'No answer'},
                {value: 1, label: 'Athletic'},
                {value: 2, label: 'Average'},
                {value: 3, label: 'A few extra pounds'},
                {value: 4, label: 'Muscular'},
                {value: 5, label: 'Big and bold'},
                {value: 6, label: 'Slim'}
            ],
            userGenderOptions: [
                {value: 1, label: 'Male'},
                {value: 2, label: 'Female'}
            ], userSmokerOptions: [
                {value: false, label: 'No'},
                {value: true, label: 'Yes'}
            ],
            id: 0,
        };
    }

    componentDidMount() {

    };

    componentDidUpdate = (prevState, prevProps) => {

    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    submit = (event) => {
        event && event.preventDefault();

        this.setState({no_match: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        interests: this.state.fields.interests.value.map((it) => ({
                            interestValue: it.value
                        })),
                        country: this.state.fields.country.value.value,
                        children: this.state.fields.children.value.value,
                        bodyTypeId: this.state.fields.bodyTypeId.value.value,
                        gender: this.state.fields.gender.value.value,
                        education: this.state.fields.education.value.label,
                        smoker: this.state.fields.smoker.value.value
                    }),
                    resource: 'api/adminusers',
                    piggyResource: 'api/adminusers'
                }));
                this.props.closeAddNewUser()
            } else {
                console.log('eror')
            }
        });
    };


    handleKeyDown = (event, value) => {
        switch (event.key) {
            case 'Enter':
                this.setState({interests: this.state.interests + value}, () => console.log(this.state.interests));
        }
    };

    render() {
        const {translate} = this.props;

        const currDate = new Date();
        return (
            <React.Fragment>
                <div className="form-container absolute-position">
                    <div className="form2">
                        <h1>Add new User</h1>
                        {!this.props.user.isLoading && !this.props.user.register && (
                            <form onKeyPress={this.submitForm}>
                                <div className="two-rows">
                                    <div>
                                        <div className="step-one">
                                            <div className="block-margin-bottom-10">
                                                <label>E-mail</label>
                                                <FieldText
                                                    onChange={this.handleInputChange}
                                                    {...this.state.fields.email}
                                                    type={"email"}
                                                    addClass={'form-input'}/>
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Name</label>

                                                <FieldText
                                                    onChange={this.handleInputChange} {...this.state.fields.name}
                                                    type={"text"} addClass={'form-input'}/>
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Date of birth</label>
                                                <br/>
                                                <FieldDate
                                                    onChange={this.handleInputChange} {...this.state.fields.date}
                                                   addClass={'form-input'}/>
                                            </div>

                                            <div className="block-margin-bottom-10">
                                                <label>Country</label>
                                                <FieldSelectSearch
                                                    values={countries}
                                                    className="field-select-search"
                                                    onChange={this.handleInputChange}
                                                    {...this.state.fields.country}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'black',
                                                            primary25: 'black',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>

                                        </div>

                                        <div className="step-two">
                                            <div className="block-margin-bottom-10">
                                                <label>City</label>

                                                <FieldText
                                                    onChange={this.handleInputChange} {...this.state.fields.city}
                                                    type={"text"} addClass={'form-input'}/>
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Height</label>

                                                <FieldText
                                                    onChange={this.handleInputChange} {...this.state.fields.height}
                                                    type={"number"} addClass={'form-input'}/>
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Smoker</label>

                                                <FieldSelectSearch
                                                    values={this.state.userSmokerOptions}
                                                    className="field-select-search"
                                                    onChange={this.handleInputChange} {...this.state.fields.smoker}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'black',
                                                            primary25: 'black',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="step-three">
                                            <div className="block-margin-bottom-10">
                                                <label>Education</label>
                                                <FieldSelectSearch
                                                    values={this.state.userEducationOptions}
                                                    className="field-select-search"
                                                    onChange={this.handleInputChange} {...this.state.fields.education}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'black',
                                                            primary25: 'black',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Children</label>
                                                <FieldSelectSearch
                                                    values={this.state.userKidsOptions}
                                                    className="field-select-search"
                                                    onChange={this.handleInputChange} {...this.state.fields.children}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'black',
                                                            primary25: 'black',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Job</label>
                                                <FieldText
                                                    onChange={this.handleInputChange} {...this.state.fields.job}
                                                    type={"text"} addClass={'form-input'}/>
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Body Type</label>
                                                <FieldSelectSearch
                                                    values={this.state.userBodyTypeIdOptions}
                                                    className="field-select-search"
                                                    onChange={this.handleInputChange} {...this.state.fields.bodyTypeId}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'black',
                                                            primary25: 'black',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>

                                        </div>
                                        <div className="step-four">
                                            <div className="block-margin-bottom-10">
                                                <label>About</label>
                                                <FieldText
                                                    onChange={this.handleInputChange} {...this.state.fields.about}
                                                    type={"text"} addClass={'form-input'}/>
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Gender</label>
                                                <FieldSelectSearch
                                                    values={this.state.userGenderOptions}
                                                    className="field-select-search"
                                                    onChange={this.handleInputChange} {...this.state.fields.gender}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            text: 'black',
                                                            primary25: 'black',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="block-margin-bottom-10">
                                                <label>Interests</label>
                                                <FieldCreatableMultiInput
                                                    onChange={this.handleInputChange}
                                                    onInputChange={(name, val) => {
                                                        this.setState({
                                                            inputValue: val
                                                        })
                                                    }}
                                                    inputValue={this.state.inputValue}
                                                    {...this.state.fields.interests}
                                                />
                                            </div>


                                        </div>

                                    </div>

                                </div>
                                <div className="buttonsD">

                                    <div className="">
                                        <Button className="sign-in-btn"
                                                onClick={()=> {
                                                    this.submit()

                                                }}
                                                text={"Add New User"}/>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                    {this.props.user.isLoading && (
                        <LoaderSmall/>
                    )}

                    {this.props.user.register && (
                        <React.Fragment>
                            <div>{translate("text.register_success")}</div>
                            <Link to={`/login`}>{translate("btn.back_to_login")}</Link>
                        </React.Fragment>
                    )}

                    {this.props.user.error && (
                        <span>{translate(this.props.user.errorMessage)}</span>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(RegisterView);