import React from 'react'

export default function Eula() {
    return (
        <div className="privacy-policy-container">
            <h1>Terms of Use</h1>
            <h3>Terms of Use last updated on 19. January 2023.</h3>

            <p className="bold">Acceptance of Terms of Use</p>
            
            <p>
                By creating a Balkan Dejt Premium account, you agree to be bound by these Terms of Use and our Privacy Policy, Cookie Policy and Safety Tips.
            </p>
            <p>
                If we make any changes to the Terms of Use, the effective date of the latest update of this Terms of Use will be updated and visible on the top of this Agreement. If you continue to use the Service after the changes become effective, then you agree to the revised agreement. If you do not accept and agree to be bound by all of the terms, please do not use the Service.
            </p>

            <p className="bold">The Rules of the Agreement</p>

            <p>
                By creating the account on the Service of Balkan Dejt Premium, you agree that you will not:
            </p>

            <ul>
                <li>Violate our Terms of Use Agreement</li>
                <li>Use the Service for any purpose that is illegal or prohibited by this Agreement or by the law</li>
                <li>Use the Service in order to hurt, humiliate, bully or intimidate other members</li>
                <li>Use the Service in order to damage Balkan Dejt Premium</li>
                <li>Spam, solicit money from or defraud any members</li>
                <li>Impersonate any person or entity or post any images of another person without their permission</li>
                <li>Post any content that violates or infringes anyone's rights, including rights of publicity, privacy, copyright, trademark or other intellectual property or contract right.</li>
                <li>Post any content that is hate speech, threatening, sexually explicit or pornographic.</li>
                <li>Post any content that incites violence; or contains nudity or graphic or gratuitous violence.</li>
                <li>Post any content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual.</li>
                <li>Use another member's account, share an account with another member, or maintain more than one account.</li>
                <li>Create another account if we have already terminated your account, unless you have our permission.</li>
            </ul>

            <p>
                Balkan Dejt Premium reserves the right to investigate and/or terminate your account without a refund of any purchases if you have violated this Agreement, misused the Service or behaved in a way that is inappropriate or unlawful, including actions or communications that occur on or off the Service. 
            </p>
            <p>
                By creating the account on our Service you agree that will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations.
            </p>
            <p className='bold'>Age restriction</p>
            <p>We are strictly 18+ only, and we work hard to maintain an adult only community. Therefor you are not authorized to create an account or access or use the Service unless:</p>
            <ul>
                <li>You are at least 18 years old</li>
                <li>You are eligible to form a binding agreement with Balkan Dejt Premium</li>
            </ul>
            <p>We may perform automatic scans of profiles to red-flag bad language and inappropriate images, including manual reviews of suspicious profiles, activity, and user generated reports, as well as blocking email addresses, phone numbers and other identifiers associated with underage users trying to circumvent these restrictions. We also depend on our users to report any profiles that may depict a minor, so that we can investigate and remove, accordingly.</p>
            <p>We do not want minors here PERIOD, so help us stop it if you see it anywhere on our website!</p>

            <p className='bold'>Safety restriction</p>
            <p>Safety of everyone on our Service is our number one policy. Therefore, you are not authorized to create an account or access or use the Service if:</p>
            <ul>
                <li>You were convicted of a felony or indictable offense, a sex crime, or any crime involving violence.</li>
                <li>You were required to register as a sex offender in any state or country</li>
                <li>You were convicted on a felony regarding fraud, blackmail  or extortion</li>
            </ul>

            <p className='bold'>Content control</p>
            <p>Any content that you post on the Service is your ownership and therefore you are solely responsible for it.</p>
            <p>You understand and agree that we may monitor or review any content you post as part of a Service. We may delete any content that violates this Agreement, as well as take necessary steps to prevent, remove, or otherwise limit objectionable content and users that submit it.</p>
            <p>Balkan Dejt Premium may access, store and disclose your account information and content if required to do so by law.</p>

            <p className='bold'>Solicitation/Prostitution:</p>
            <p>Solicitation of any kind, whether trying to buy or sell, is against our Terms of Use. If a user encounters a profile violating our terms, we encourage them to report it to us immediately. Making such a report allows our dedicated community team to take appropriate measures, which may include removing the profile or banning the user.</p>
            <p className='bold'>Harassment</p>
            <p>We have a zero-tolerance policy for harassment on our platform. We encourage all users to report any unacceptable behavior so that the community team can investigate and take appropriate action.</p>
            <p className='bold'>Illegal Substances:</p>
            <p>Profiles or users promoting illegal substances violate our Terms of Use and we have a system in place to remove these profiles from the app. If a user encounters a profile violating our terms, we encourage them to report it to us.</p>
            <p>Fraud</p>
            <p>We take the issue of fraud very seriously and we have a zero-tolerance policy on predatory behavior of any kind. Ultimately, no one, whether they met on our website or not, should ever send money to someone they haven't met in person. In addition, we encourage our members to report any individual who has requested financial information via our self reporting tool. This will greatly assist in stopping almost every scam in its tracks and help protect the next potential victim.</p>
            <p className='bold'>Violation of the Terms of Use by Other Members</p>
            <p>We reserve the right to review and remove any content that violates our Terms of Use agreement. Nevertheless, such content is the sole responsibility of the member who posts it, and we cannot guarantee that all members will comply with this Agreement. If you see any content on the Service that violates this Agreement, please report it to the Service.</p>
            <p className='bold'>Subscription terms</p>
            <p>Balkan Dejt Premium is free (except for additional services) and using it works on "as is" principal. We are not responsible for possible consequences arising from using the site.</p>
            <p>If you wish to have special treatment and advertising free experience whilst using our Service, please visit our Application guide and subscribe.</p>
            <p className='bold'>Limitation of Liability</p>
            <p>In no event shall BalkanDejt or its suppliers be liable for any damages, including, without limitation, damages for loss of data or profit, or due to business interruption, resulting from the use or inability to use the materials in our app, even if have been notified orally or in writing of the possibility of such damage.</p>
            <p>Some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, so these limitations may not apply to you.</p>
            <p className='bold'>Governing Law</p>
            <p>The Terms of Use and any dispute or claim arising out of or in connection with it or its subject matter (including non-contractual disputes or claims) shall be governed by and construed in accordance with Serbian law, without regard to its conflict of law provisions.</p>
        </div>
    )
}