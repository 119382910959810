import React, {Component} from "react";
import {connect} from "react-redux";
import PublicLayout from "../../components/layout";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import Logout from "../../components/logout/index";
import {getResource} from "../../data/actions/resource";

class HomeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: ''
        };
    }

    componentDidMount() {
        this.fetchData()
    };

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            resource: `api/profile/me`,
        }));
    };


    render() {
        const {translate, history, dispatch, resource} = this.props;
        const data = getProp(resource.data, "item", [])
        const {name} = data;
        return (
            <PublicLayout {...this.props}>
                <h1>{translate("text.home")}</h1>
                <h1>Hello {name}</h1>

                <div className="link-wrapper">
                    <Logout history={history} translate={translate} dispatch={dispatch}/>
                </div>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(HomeView);