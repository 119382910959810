import React from 'react'


const UpdateStatus = (props) => {


    return (
        <div>

            <div className="images-dialog">

                <div className="status-dialog">

                    <h2>Update status ( current : {props.status} )</h2>
                    <div className="select">
                        <button onClick={() => {
                            props.returnStatus(1)
                            props.closeStatusDialog()
                        }}>1 - rejected
                        </button>
                        <button onClick={() => {
                            props.returnStatus(2)
                            props.closeStatusDialog()
                        }}>2 - accepted
                        </button>
                    </div>

                    <div className="buttons">
                        <button onClick={() => props.closeStatusDialog()}>Cancel</button>
                    </div>
                    <div className="inner-dialog-close"
                         onClick={() => props.closeStatusDialog()}>
                        <i className="fas fa-times"></i>
                    </div>
                </div>



            </div>

        </div>
    )
}

export default UpdateStatus