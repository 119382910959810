export function getResource(data) {
    return {
        type: 'GET_RESOURCE',
        data: data
    };
}

export function doneGetResource(data) {
    return {
        type: 'DONE_GET_RESOURCE',
        data: data
    };
}

export function errorGetResource(data) {
    return {
        type: 'ERROR_GET_RESOURCE',
        data: data
    };
}

export function createResource(data) {
    return {
        type: 'CREATE_RESOURCE',
        data: data
    };
}

export function doneCreateResource(data) {
    return {
        type: 'DONE_CREATE_RESOURCE',
        data: data
    };
}

export function errorCreateResource(data) {
    return {
        type: 'ERROR_CREATE_RESOURCE',
        data: data
    };
}

export function updateResource(data) {
    return {
        type: 'UPDATE_RESOURCE',
        data: data
    };
}

export function doneUpdateResource(data) {
    return {
        type: 'DONE_UPDATE_RESOURCE',
        data: data
    };
}

export function errorUpdateResource(data) {
    return {
        type: 'ERROR_UPDATE_RESOURCE',
        data: data
    };
}
export function updateImage(data) {
    return {
        type: 'UPDATE_IMAGE',
        data: data
    };
}

export function doneUpdateImage(data) {
    return {
        type: 'DONE_UPDATE_IMAGE',
        data: data
    };
}

export function errorUpdateImage(data) {
    return {
        type: 'ERROR_UPDATE_IMAGE',
        data: data
    };
}
export function deleteResource(data) {
    return {
        type: 'DELETE_RESOURCE',
        data: data
    };
}

export function doneDeleteResource(data) {
    return {
        type: 'DONE_DELETE_RESOURCE',
        data: data
    };
}

export function errorDeleteResource(data) {
    return {
        type: 'ERROR_DELETE_RESOURCE',
        data: data
    };
}

export function resetResourceMessage() {
    return {
        type: 'RESET_RESOURCE_MESSAGE'
    }
}