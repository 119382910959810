import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/promo.css";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'

class PromoView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            color: false,
            menu: false,
            isLoading: true
        };
    }

    listenScrollEvent = e => {
        if (window.scrollY > 300) {
            this.setState({ color: true });
            this.setState({ menu: true });
        } else {
            this.setState({ color: false });
            this.setState({ menu: false });
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent);
        // setTimeout(() => {
        //     this.setState({isLoading: false})
        // }, 2500);
        this.setState({ isLoading: false })
    }

    render() {

        return (
            <React.Fragment>
                {this.state.isLoading ?
                    <div class="loader">
                        <div class="inner one"></div>
                        <div class="inner two"></div>
                        <div class="inner three"></div>
                    </div>
                    :
                    <React.Fragment>
                        {this.state.color && <a href="#container" className="backToTop">

                            <i class="fas fa-chevron-up"></i>

                        </a>}
                        <div id="container" className="container">
                            <div className={this.state.menu ? "menu" : "menub"}>
                                <div className="menu-logo">
                                    <img className="logo-image" src={'images/BalkandejtPremium.png'} />
                                </div>
                                <div className={"menu-links "}>
                                    <a href="#container">HOME</a>
                                    <a href="#overview">OVERVIEW</a>
                                    <a href="/public-support">SUPPORT</a>
                                    <a href="#download">DOWNLOAD</a>
                                </div>
                            </div>
                            <div className="content">
                                <div className="content-text">
                                    <h1>BALKANDEJT Premium - DATING BEYOND BORDERS</h1>
                                    <p>Welcome to BalkanDejt Premium - a place where people connect</p>

                                    <div className="buttons">
                                        <a href="#download" className="button1">
                                            GET APP NOW <i class="fas fa-download"></i>
                                        </a>

                                        <a href="#overview" className="button2">
                                            DISCOVER MORE <i class="fas fa-info-circle"></i>
                                        </a>
                                    </div>
                                </div>

                                <div className="">
                                    {/*
                                    <div className='player-wrapper'>
                                        <ReactPlayer
                                            className='react-player fixed-bottom'
                                            url= 'images/balkandejt_video.mp4'
                                            width='100%'
                                            height='100%'
                                            playing={true}
                                            playInline={true}
                                            autoPlay={true}
                                            loop={false}
                                            volume={0}
                                            muted={true}
                                            controls={true}
                                        />
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                        <div id="overview" className="a"></div>
                        <div className="overviewContainer">
                            <div className="overview">
                                <h1>Looking for true love, romance, flirting, or dating?</h1>
                                <div className="border"></div>

                                <h3>BalkanDejt brings you closer to new and fascinating people from all around the World!</h3>

                                <h3>Why not date someone from Croatia, Sweden, or maybe Germany?</h3>
                                <h3>BalkanDejt makes it possible for you to find your love in another country.</h3>
                            </div>
                        </div>
                        <div className="container-b">

                            <div className="boxes">
                                <div className="box">
                                    <i class="fas fa-heart"></i>
                                    <div className="box-h2">
                                        <h2>Ready to indulge in the charms of online dating?</h2>
                                    </div>
                                    <div className="border"></div>
                                    <p>Whether you are single looking for that special someone, new acquaintances or just flirtation, BalkanDejt is the place created just for you. The application is very intuitive and easy to use.
After registering, create a profile and share your interests with others. The application algorithm will choose compatible users who are the perfect fit for you.</p>
                                </div>
                                <div className="box">
                                    <i class="fas fa-thumbs-up"></i>
                                    <div className="box-h2">
                                        <h2>It all starts with one like</h2>
                                    </div>
                                    <div className="border"></div>
                                    <p>Like the profile that caught your eye and the conversation can begin.
                                        Everything else is up to you!</p>
                                </div>
                                <div className="box">
                                    <i class="fas fa-user-check"></i>
                                    <div className="box-h2">
                                        <h2>Verified users</h2>
                                    </div>
                                    <div className="border"></div>
                                    <p>No fake profiles. We verify all users via photos, social media accounts, or
                                        emails.</p>

                                </div>
                                <div className="box">
                                    <i class="fas fa-user-shield"></i>
                                    <div className="box-h2">
                                        <h2>We care about your privacy</h2>
                                    </div>
                                    <div className="border"></div>
                                    <p>Your location and personal information are always protected. You decide if, when,
                                        and how you want to connect with others.</p>
                                </div>
                            </div>
                        </div>

                        <div className="container-c">
                            <div className="download-image">
                                {/* <img src="images/b.png" /> */}
                            </div>
                            <div  className="download-text">
                                <div className="header">
                                    <h1>Download Our App</h1>
                                    <div className="border"></div>
                                </div>
                                <div className="paragraphs">
                                    <h1>
                                        Meet and start dating new people today.
                                    </h1>
                                    <h3 id="download">
                                        Amazing singles are waiting for you. What are you waiting for?
                                    </h3>
                                </div>
                                <div className="paragraphs">
                                    <h1>
                                        Download our app and create your profile today! </h1>
                                    <h3>
                                        Available for free download on the App Store and Google Play Store.
                                    </h3>
                                </div>
                                <div className="downloadbuttons mt">
                                    <a target={"_blank"} href="https://play.google.com/store/apps/details?id=com.balkandejt">
                                        <img src="images/googleplay.png" alt={""}/>
                                    </a>
                                    <a target={"_blank"} href="https://apps.apple.com/us/app/balkandejt-premium/id6444157664">
                                        <img src="images/app-store.png" alt={""}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="lines">
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div className="socials">
                                <div className="i-wrapper">
                                    <a href={"https://www.facebook.com/profile.php?id=100083100403993"} target={"_blank"}>
                                        <i className="fab fa-facebook-f"/>
                                    </a>
                                </div>
                                <div className="i-wrapper">
                                    <a href={"https://www.instagram.com/balkandejtpremium/"} target={"_blank"}>
                                        <i className="fab fa-instagram"/>
                                    </a>
                                </div>
                            </div>
                            <div className="copyright">
                                &copy;Copyright by BalkanDejt Premium - company BD Media AB
                            </div>
                            <div className="copyright">
                                <Link to="/privacy-policy" target="_blank" className={"margin-left-5"}>
                                    Privacy Policy
                                </Link>
                                <Link to="/terms_of_use" target="_blank" className={"margin-left-5"}>
                                    Terms Of Use
                                </Link>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default connect(state => state)(PromoView);